import {Component, OnInit, TemplateRef} from '@angular/core';
import {ZayavService} from '../shared/zayav.service';
import {RepositoryModel} from '../shared/model/repository.model';
import {AbiturientsModel} from '../shared/model/abiturients.model';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router, Params} from '@angular/router';
import {ErrorModel} from '../shared/model/error.model';
import {CommonModel} from '../../shared/model/common.model';
import {CompetitionChoicesModel} from '../shared/model/competition-choices.model';
import {ImmutableDataModel} from '../shared/model/immutable-data.model';
import {SpravModel} from '../shared/model/sprav.model';
import {IAddStudents} from '../shared/model/interfaces';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {Observable} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-add-abit',
  templateUrl: './add-abit.component.html',
  styleUrls: ['./add-abit.component.css']

})
export class AddAbitComponent implements OnInit {

  abitData: AbiturientsModel;
  abitId: number = null;
  myForm: FormGroup;
  form: FormGroup;
  error: ErrorModel;
  groupLists: CompetitionChoicesModel[] = [];
  instituteLists: any[] = [];
  active: boolean = false;
  ikt: number;
  public subjectOlymp = [];
  immutableData: ImmutableDataModel = new ImmutableDataModel();
  public eduProgramLists: SpravModel[];
  public olympTypeAbit = [];
  competitionChoices: any = [];
  selectedPersonId: number;
  studyFormId: number;
  stageStatus: number = 1;
  modalRef: BsModalRef;
  myControl = new FormControl();
  foreignLangCertificateTypeId: number;
  foreignLangCertificateType: number;
  certificateType: any;
  showNumberScore: boolean;
  showLettersScore: boolean;
  tvorCheck = false;
  sertifType: number;
  characters = [];
  isPending = false;
  student: any;
  agr = false;


  constructor(
    public repositoryModel: RepositoryModel,
    public router: Router,
    public dataSource: ZayavService,
    public commonModel: CommonModel,
    public route: ActivatedRoute,
    private _fb: FormBuilder,
    private modalService: BsModalService,
    public translate: TranslateService
  ) {

  }

  ngOnInit() {
    this.myForm = this.formValidation(new AbiturientsModel());
    this.error = new ErrorModel();
    this.route.params.subscribe(params => {
      this.ikt = params.ikt;
      if (params.edit) {
        this.editStudent();
      } else {
        this.findStudent();
      }
    });
    this.dataSource.certifaceType().subscribe(data => {
      this.certificateType = data.data;
    });
  }

  setScoreType(id: number) {
    this.dataSource.selectScoreType(id)
      .subscribe(data => {
        // this.myForm.get('foreignLangCertificateScore').setValue(0);
        if (data.data[length] < 1) {
          this.myForm.get('foreignLangCertificateType').setValue(1);
          this.showNumberScore = true;
          this.showLettersScore = false;
        } else {
          this.myForm.get('foreignLangCertificateType').setValue(2);
          this.characters = data.data;
          this.showNumberScore = false;
          this.showLettersScore = true;
        }
      });
  }

  toggleDivVisibility(checked: boolean) {
    this.myForm.get('isGreExists').patchValue(checked ? 1 : 0);
    if (!checked) {
      this.myForm.get('greRegNumber').patchValue(null);
      this.myForm.get('grePrintDate').patchValue(null);
      this.myForm.get('quantitativeReasoningScore').patchValue(null);
      this.myForm.get('analyticalWritingScore').patchValue(null);
      this.myForm.get('verbalReasoningScore').patchValue(null);
      this.myForm.get('greTestDate').patchValue(null);
    }
  }

  isButtonActive(): boolean {
    // if (this.myForm.get("agr").value) {
    //   return true;
    // } else {
    //   return false;
    // }
    return (this.myForm.get("isGreExists").value  && this.myForm.get("agr").value ) || (!this.myForm.get("isGreExists").value && !this.myForm.get("agr").value );
  }

  findStudent() {
    this.dataSource.findStudentExtended(this.ikt)
      .subscribe(data => {
        this.student = data;
        this.immutableData = this.immutableDataSave(data.data);
        this.tvorCheck = data.data.creative;
        this.foreignLangCertificateTypeId = data.data.foreignLangCertificateTypeId;
        this.foreignLangCertificateType = data.data.foreignLangCertificateType;
        // this.olympTypeByIkt(data.data.ikt);
        this.studyFormId = data.data.studyFormId;
        if (data.data.studyFormId) {
          this.getEduProgram(data.data.ikt, data.data.studyFormId);
        }
        this.myForm = this.formValidation(data.data);
        if (data.data.olimpTypeId) {
          this.olimpSubjectByIktAndOlimpType(data.data.olimpTypeId);
        }
        this.setScoreType(this.myForm.get('foreignLangCertificateTypeId').value);
        if (this.myForm.get('foreignLangCertificateType').value == 1) {
          this.showNumberScore = true;
          this.showLettersScore = false;
        }
        if (this.myForm.get('foreignLangCertificateType').value == 2) {
          this.showNumberScore = false;
          this.showLettersScore = true;
        }
      });
  }

  immutableDataSave(data) {

    return new ImmutableDataModel(
      data.firstname,
      data.lastname,
      data.patronymic,
      data.iin,
      data.certificateNumber,
      data.certificateSeries,
      data.testLangKaz,
      data.testLangRus,
      data.foreignLangCertificateType,
      data.foreignLangCertificateTypeId,
      data.foreignLangCertificateScore,
      data.foreignLangCertificateTypeRus,
      data.foreignLangCertificateTypeKaz,
      data.gpa
    );
  }

  langCert(langId: string) {
    if (!+langId) {
      this.myForm.controls['foreignLangCertificateScore'].setValue(0);
    }
  }

  editStudent() {
    this.dataSource.findActiveAbiturient(this.ikt)
      .subscribe(data => {
        this.immutableData = this.immutableDataSave(data.data);
        this.myForm = this.formValidation(data.data);
        // this.olympTypeByIkt(data.data.ikt);
        this.getEduProgram(data.data.ikt, data.data.studyFormId);
        if (data.data.olimpTypeId) {
          this.olimpSubjectByIktAndOlimpType(data.data.olimpTypeId);
        }
      });
  }


  findCatalogueInstitute(eduProgramId: any, index: number = null, start: boolean = true) {
    return new Promise<void>(resolve => {
      this.dataSource.findCatalogueInstitute({ikt: this.ikt})
        .subscribe(data => {
          if (data.data.length) {
            this.instituteLists[index] = ([data.data]);
          }
          if (this.competitionChoices[index] && !start) {
            this.competitionChoices[index] = new CompetitionChoicesModel(+eduProgramId, null, index + 1, null);
          }
          resolve();
        }, error => {
          this.commonModel.message(this.error, this.translate.currentLang === 'ru' ? error.errorMessageRu : error.errorMessageKz, 'danger');
        });
    });
  }

  save() {
    this.formValue();
    if (this.competitionChoices.length !== Object.keys(this.competitionChoices).length || this.competitionChoices.length !== this.competition.length) {
      return this.commonModel.message(this.error, this.translate.currentLang === 'ru' ? 'Не указан полностью один из выборов поступающего' : 'Түсушінің таңдауының бірі толық көрсетілмеген', 'danger');
    }
    if (this.myForm.get('isGreExists').value == 1 && this.myForm.get('greRegNumber').value.length === 0) {
      return this.commonModel.message(this.error, this.translate.currentLang === 'ru' ? 'Не указан Registration Number' : 'Түсушінің таңдауының Registration Number көрсетілмеген', 'danger');
    }
    this.abiturientsAdd(this.myForm.value);
  }

  abiturientsAdd(data) {
    this.isPending = true;
    this.dataSource.abiturientsAdd(data)
      .subscribe(data => {
        let find = this.repositoryModel.abiturientsLists.find(item => data.data.ikt === item.ikt);
        for (let item in find) {
          find[item] = data.data[item];
        }
        this.abitId = data.data.id;
        this.active = true;
        this.myForm.disable();
        this.active = true;
        this.repositoryModel.update(data.data);
        this.stageStatus = 2;
        this.commonModel.message(this.error, this.translate.currentLang === 'ru' ? 'Поступающий успешно сохранен' : 'Түсуші сәтті сақталды');
        this.repositoryModel.abitLists = [];
        this.isPending = false;
      }, error => {
        this.commonModel.message(this.error, this.translate.currentLang === 'ru' ? error.errorMessageRu : error.errorMessageKz, 'danger');
        this.isPending = false;
      });
  }

  registration() {
    this.dataSource.registerAbiturients(this.abitId)
      .subscribe(data => {
        this.stageStatus = 4;
        this.commonModel.message(this.error, this.translate.currentLang === 'ru' ? 'Поступающий успешно зарегистрирован' : 'Түсуші сәтті тіркелді');
      }, error => {
        this.commonModel.message(this.error, this.translate.currentLang === 'ru' ? error.errorMessageRu : error.errorMessageKz, 'danger');
      });
  }


  // olympTypeByIkt(ikt: number) {
  //   this.dataSource.olimpTypeByIkt(ikt)
  //     .subscribe(data => {
  //       this.olympTypeAbit = data.data;
  //     });
  // }

  olimpSubjectByIktAndOlimpType(olympTypeId: string) {
    this.dataSource.olimpSubjectByIktAndOlimpType(this.myForm.value.ikt, +olympTypeId)
      .subscribe(data => {
        this.subjectOlymp = data.data;
      });
  }


  getEduProgram(ikt: number, formId: number) {
    this.dataSource.getEduProgram(ikt, formId)
      .subscribe(data => {
        this.eduProgramLists = data.data;
      }, error => {
        console.log(error);
      });
  }

  edit() {
    this.stageStatus = 1;
    this.myForm.enable();
    this.active = !this.active;
  }


  get formsValues(): number {
    return +this.myForm.get('foreignLangCertificateTypeId').value;
  }


  get competition(): FormArray {
    return this.myForm.get('competitionChoices') as FormArray;
  }

  getInstitute(index: number, eduProgramId: number, instituteId: number) {

    if (this.competitionChoices[index]) {
      this.competitionChoices[index] = new CompetitionChoicesModel(+eduProgramId, +instituteId, index + 1, 0);
    } else {
      this.competitionChoices[index] = new CompetitionChoicesModel(+eduProgramId, +instituteId, index + 1, 0);
    }

  }

  quotaChange(e: any, index: number) {
    this.competitionChoices[index].useQuotaGrants = e.target.checked ? 1 : 0;
  }

  delete(index = 0) {
    if (!this.active) {
      this.instituteLists.splice(index, 1);
      this.competitionChoices.splice(index, 1);
      this.competitionChoices.forEach((item, i) => {
        item['choiceOrder'] = i + 1;
      });
      this.competition.removeAt(index);
      this.formValue();
    }
  }

  addGroup() {
    this.competition.push(new FormGroup({
      choiceOrder: new FormControl(null),
      eduProgramId: new FormControl(null),
      instituteId: new FormControl(null),
      useQuotaGrants: new FormControl(null)
    }));
    this.formValue();
  }

  formValue() {
    this.myForm.value['competitionChoices'] = this.competitionChoices;
  }

  checked(event, form) {
    if (event.target.checked) {
      this.myForm.value[form] = 1;
    } else {
      this.myForm.value[form] = 0;
    }
  }

  formArray(arr = []) {
    const form = [];
    let i = 0;
    if (arr.length) {
      arr.forEach((item, i) => {
        form.push(new FormGroup({
          choiceOrder: new FormControl(i + 1),
          eduProgramId: new FormControl(item.eduProgramId),
          instituteId: new FormControl(item.instituteId),
          useQuotaGrants: new FormControl(item.useQuotaGrants)
        }));
        this.competitionChoices[i] = new CompetitionChoicesModel(+item.eduProgramId, +item.instituteId, i + 1, item.useQuotaGrants);

      });
      this.getIns(arr);
      return form;
    } else {
      return [new FormGroup({
        choiceOrder: new FormControl(null),
        eduProgramId: new FormControl(null),
        instituteId: new FormControl(null),
        useQuotaGrants: new FormControl(null)
      })];
    }
  }

  async getIns(arr) {
    let i = 0;
    for (const item of arr) {
      await this.findCatalogueInstitute(item.eduProgramId, i);
      i++;
    }
  }

  formValidation(data: AbiturientsModel, status: boolean = false) {
    // IAddStudents
    return new FormGroup({
      ikt: new FormControl({value: data.ikt, disabled: status}),
      iin: new FormControl({value: data.iin, disabled: status}),
      studyFormId: new FormControl({value: data.studyFormId, disabled: status}),
      competitionChoices: new FormArray(this.formArray(data.instituteEduPrograms)),
      foreignLangCertificateTypeId: new FormControl(data.foreignLangCertificateTypeId),
      foreignLangCertificateType: new FormControl(data.foreignLangCertificateType),
      foreignLangCertificateScore: new FormControl(data.foreignLangCertificateScore),
      creativeScore1: new FormControl(data.creativeScore1),
      creativeScore2: new FormControl(data.creativeScore2),
      gpa: new FormControl(data.gpa),
      foreignLangCertificateDate: new FormControl(data.foreignLangCertificateDate),
      foreignLangCertificateRegNumber: new FormControl(data.foreignLangCertificateRegNumber),
      greRegNumber: new FormControl(data.greRegNumber),
      grePrintDate: new FormControl(data.grePrintDate),
      quantitativeReasoningScore: new FormControl(data.quantitativeReasoningScore),
      analyticalWritingScore: new FormControl(data.analyticalWritingScore),
      verbalReasoningScore: new FormControl(data.verbalReasoningScore),
      isGreExists: new FormControl(data.isGreExists),
      agr: new FormControl(data.agr),
      greTestDate: new FormControl(data.greTestDate)
    });
  }

  resetGroup(id: number) {
    if (this.active) {
      return;
    }
    this.studyFormId = id;
    if (this.myForm.value.studyFormId !== id) {
      this.eduProgramLists = [];
      this.getEduProgram(this.ikt, id);
      this.competitionChoices = [];
      this.instituteLists = [];
      this.myForm.get('competitionChoices')['controls'] = [];
    }
  }


  getSubjectOlimp(olympId: number) {
    this.subjectOlymp = [];
    this.myForm.value.olimpSubjectId = null;
    this.dataSource.olimpSubjectByIktAndOlimpType(this.ikt, olympId)
      .subscribe(data => {
        this.subjectOlymp = data.data;
      });
  }

  preview(lang: number) {
    const token = JSON.parse(sessionStorage.getItem('user')).token;
    window.open(`./konkurs/zayav/pvo/api/forms/validationForRegistration?id=${this.abitId}&langId=${lang}&format=pdf&token=${token}`);
    this.stageStatus = 3;
  }

  previewLang(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {class: 'modal-sm'});
  }

  certificate(lang: number) {
    const token = JSON.parse(sessionStorage.getItem('user')).token;
    window.open(`./konkurs/zayav/pvo/api/forms/certificate?ikt=${this.ikt}&langId=${lang}&token=${token}`);
  }

  count(id: number) {
    if (id.toString().length < 3) {
      return new Array(3 - id.toString().length).fill(0).join('') + id;
    }
    return id;
  }

  decline() {
    this.modalRef.hide();
  }


}









